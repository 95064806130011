import { render, staticRenderFns } from "./promotionEdit.vue?vue&type=template&id=2e586c5b&scoped=true&"
import script from "./promotionEdit.vue?vue&type=script&lang=js&"
export * from "./promotionEdit.vue?vue&type=script&lang=js&"
import style0 from "./promotionEdit.vue?vue&type=style&index=0&id=2e586c5b&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e586c5b",
  null
  
)

export default component.exports