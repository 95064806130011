<template>
	<div class="add-discount" style="margin-bottom: 200px;" v-loading="loading">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<div style="padding: 15px;background: #fff;">
				<el-form-item label="活动名称：" label-width="140px" prop="activityName">
					<el-input :disabled="type==2" v-model="ruleForm.activityName" placeholder="请输入活动名称" maxlength="50" style="width:200px">
					</el-input>
				</el-form-item>
				<el-form-item label="活动时间：" label-width="140px" prop="activityTime">
					<el-date-picker  :disabled="type==2 || onlyLook" v-model="ruleForm.activityTime" type="datetimerange"
						:picker-options="pickerOptions" range-separator="～" start-placeholder="开始日期"
						end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				</el-form-item>
				<el-form-item label="每人每天可点赞：" label-width="140px" prop="limitLikeCount">
					<el-input :disabled="type==2" onkeyup="this.value=this.value.replace(/\D/g,'')" v-model.number="ruleForm.limitLikeCount"
						style="width: 200px;margin-left: 4px;"><template slot="append">次</template></el-input>
				</el-form-item>
				<el-form-item label="分享标题：" label-width="140px" prop="shareTitle">
					<el-input :disabled="type==2" v-model="ruleForm.shareTitle" placeholder="请输入活动分享标题" maxlength="30" style="width:200px">
					</el-input>
				</el-form-item>
				<div style="margin-top: 30px;">
					<el-form-item label="分享图片：" label-width="140px" :inline-message="true" prop="shareImgUrl"
						:rules="[{required:true,message:'请上传分享图片',trigger: 'blur'}]">
						<el-upload :disabled="type==2" class="avatar-uploader" :action="imgApi" :show-file-list="false"
							:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
							<img v-if="ruleForm.shareImgUrl"
								:src="ruleForm.shareImgUrl+'?x-oss-process=image/resize,m_fixed,h_146,w_146'"
								style="width:146px;height:146px;" class="avatar" />
							<i v-else class="el-icon-plus"
								style="width:144px;height:84px;font-size: 24px;color:#999999;margin-top:60px;"></i>
						</el-upload>
						<div style="color: #666;">建议5：4尺寸，支持JPG、PNG、JPEG格式</div>
					</el-form-item>
				</div>
				<el-form-item label="活动规则：" label-width="140px" prop="rules">
					<div class="baseInformtion">
						<div class="baseBottom-box" style="margin-bottom:150px;">
							<quill-editor :disabled="type==2" ref="myTextEditor" v-model="ruleForm.rules" :options="quillOption"
								style="width:80%;min-height: 300px;height:650px;"></quill-editor>
						</div>
					</div>
				</el-form-item>
			</div>
		</el-form>
		<div class="footer">
			<el-button style="width:240px;" @click="suppilerCansel">取消</el-button>
			<el-button style="width:240px;" type="primary" @click="saveSupiler('ruleForm')" :loading="loading">保存
			</el-button>
		</div>
	</div>
</template>

<script>
	import {
		quillEditor
	} from 'vue-quill-editor'
	import config from '../../../config/index.js';
	import quillConfig from '@/components/quill-config.js'
	import {
		addPromotionActivity,
		updateActivity,
		getpromotionActivity,
	} from '@/api/wyUsedInterface.js'
	export default {
		components: {
			quillEditor,
		},
		data() {
			return {
				ruleForm: {
					activityName: '', //活动名称
					beginDate: '', //活动开始时间
					endDate: '', //活动结束时间
					limitLikeCount: '', //每日点赞次数
					shareTitle: '', //分享标题
					shareImgUrl: '', //分享图片
					rules: '', //活动规则说明
					activityTime: '', //活动时间
				},
				rules: {
					activityName: [{
							required: true,
							trigger: 'blur',
							message: '请输入活动名称',
						},
						{
							min: 1,
							max: 50,
							message: '最多输入50个字',
							trigger: 'blur'
						}
					],
					activityTime: [{
						required: true,
						message: '请选择活动时间',
						trigger: 'change'
					}],
					shareTitle: [{
						required: true,
						trigger: 'blur',
						message: '请输入活动分享标题',
					}],
					limitLikeCount: [{
							required: true,
							trigger: 'blur',
							message: '请输点赞次数',
						},
						{
							pattern: /^\+?[1-9]\d*$/,
							message: '每人每天可点赞必须≥1',
							trigger: 'blur'
						}
					],
					rules: [{
						required: true,
						trigger: 'blur',
						message: '请填写活动规则',
					}],
				},
				pickerOptions: { //时间区间提示
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				quillOption: quillConfig, //富文本
				loading: false,
				imgApi: config.UPLOAD_IMG,
				imgUrl: config.IMG_BASE,
				onlyLook: false,
				type: 0, //0 添加 1编辑 2查看
				id: 0,
			}
		},
		async beforeMount() {
			this.onlyLook = this.$route.query.isContinue == 1;
			this.type = this.$route.query.type;
			this.id = this.$route.query.id;
			this.getList();
		},
		computed: {

		},
		methods: {
			// 获取列表数据
			async getList() {
				this.loading = true
				try {
					let data = {
						id: this.id
					};
					let result = await getpromotionActivity(data);
					if (result.success) {
						this.ruleForm = {
							activityName: result.data.activityName, //活动名称
							beginDate: result.data.beginDate, //活动开始时间
							endDate: result.data.endDate, //活动结束时间
							limitLikeCount: result.data.limitLikeCount, //每日点赞次数
							shareTitle: result.data.shareTitle, //分享标题
							shareImgUrl: result.data.shareImgUrl, //分享图片
							rules: result.data.rules, //活动规则说明
							activityTime: [result.data.beginDate, result.data.endDate], //活动时间
						}
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: result.alertMsg ? result.alertMsg : '数据加载失败'
						});
					}
				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			// 上传奖品图成功钩子
			handleAvatarSuccess(res) {
				this.ruleForm.shareImgUrl = res.data[0];
				return true;
			},
			// 上传图前钩子
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
				// const isLt3M = file.size / 1024 / 1024 < 3;
				if (!isJPG) {
					this.$message.error('上传图片必须是 JPG 或者PNG格式!');
				}
				// if (!isLt3M) {
				// 	this.$message.error('图片大小请控制在3M以内!');
				// }
				return isJPG;
			},
			// 取消
			suppilerCansel() {
				this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
					(item) => {
						return item.path != this.$route.path;
					}
				);
				this.$router.push({
					path: '/market/promotionActivities/index'
				});
			},
			// 保存
			saveSupiler(ruleForm) {
				this.$refs[ruleForm].validate(async (valid) => {
					if (valid) {
						console.log('valid submit!!');
						this.saveEdit();
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			async saveEdit() {
				this.loading = true
				try {
					if (this.ruleForm.activityTime) {
						this.ruleForm.beginDate = this.ruleForm.activityTime[0];
						this.ruleForm.endDate = this.ruleForm.activityTime[1];
					} else {
						this.ruleForm.beginDate = '';
						this.ruleForm.endDate = '';
					}
					let data = this.ruleForm;
					let result = '';
					if (this.id > 0) {
						data.id = this.id;
						result = await updateActivity(data);
					} else {
						result = await addPromotionActivity(data);
					}
					if (result.success) {
						this.$message({
							showClose: true,
							type: 'success',
							message: "保存成功"
						});
						this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
							(item) => {
								return item.path != this.$route.path;
							}
						);
						this.$router.push({
							path: '/market/promotionActivities/index'
						});
					} else {
						this.$message({
							showClose: true,
							type: 'error',
							message: result.alertMsg ? result.alertMsg : '保存失败'
						});
					}
				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.baseInformtion {
		width: 100%;
		background: #fff;
		padding: 10px;
		margin-bottom: 12px;

		.infortion {
			margin-bottom: 20px;
			padding: 10px 0;
			border-bottom: 1px solid #ccc;
		}
	}

	/deep/.el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
		height: 146px;
	}

	/deep/.el-upload:hover {
		border-color: #409eff;
	}

	.footer {
		box-shadow: 13px 1px 6px #999;
		line-height: 80px;
		background-color: #fff;
		position: fixed;
		bottom: 0px;
		width: 100%;
		text-align: center;
	}
</style>
